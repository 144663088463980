<template>
  <div class="showreel flex-column-between" @click="handleALL">
    <entrance :sitedata="changeable" @renewal="renewal" @changTip="changTip"></entrance>
    <div class="iput flex-align-center">作品集</div>
    <div class="operationBtn" v-if="changeable">
      <div class="Administration flex-align-center" v-if="DleBtn" @click="showDleBtn">
        管理
      </div>
      <div class="del flex-align-between" v-else>
        <div class="flex-align-center" @click="cancel">取消</div>
        <div class="flex-align-center" :class="{ delBtnStyle: arr.length != 0 }" @click="del">
          删除
        </div>
      </div>
    </div>
    <div class="PageHeader" v-if="!changeable">
      当前位置：<span @click="direction">作品集</span> >
      <span @click="direction">{{ class1 }}</span> >
      <span>【{{ class2 }}】的作品</span>
    </div>
    <div class="switchType flex-column-center" v-show="changeable">
      <div class="stuWorks flex-align-center" :class="{ btncolor: options == true }" @click="stuWorks">
        学生作品集
      </div>
      <div class="personalWorks flex-align-center" :class="{ btncolor: options == false }" @click="personalWorks">
        我的作品集
      </div>
    </div>
    <div class="explain flex-align-center" v-if="changeable">
      * 更新说明：参加活动提交的作品，将在每次任务结束后24小时内进行更新。敬请期待
    </div>

    <div class="main" v-if="changeable">
      <ul>
        <li v-for="(item, index) in Object.keys(worksList).sort().reverse()" :key="index">
          <div class="courseDate">
            <span>{{ item }}</span>
            <span>{{ weeks[new Date(item).getDay()] }}</span>
          </div>
          <el-timeline :simple="false">
            <el-timeline-item v-for="(itemSon, indexSon) in worksList[item]" :key="indexSon"
              :timestamp="renderTime(itemSon.startTime || itemSon.uploadTime)" placement="top">
              <div class="courseware flex-align-around" :class="{
                  coursewarHover:
                    addHover &&
                    qrcodeIndexSon == indexSon &&
                    qrcodeIndex == index,
                }" @click.self="
                  options == null || options == true
                    ? goWord(itemSon.lessonId,itemSon.wordName)
                    : goWordPersonal(itemSon.workName)
                " @mouseenter.self="enter(indexSon, index)" @mouseleave.self="leave(indexSon, index)">
                <div class="type flex-align-between" @click="
                    options == null || options == true
                      ? goWord(itemSon.lessonId,itemSon.wordName)
                      : goWordPersonal(itemSon.workName)
                  ">
                  <!-- <span v-show="false"></span> -->
                  <!-- <span
                                                            :class="{'lecture':itemSon.type == '讲授课'}">{{itemSon.type}}</span> -->
                  <span v-if="options == null || options == true">{{ itemSon.className }} 【{{ itemSon.wordName }}】
                    的学习</span>
                  <span v-else>【{{ itemSon.workName }}】的学习</span>
                </div>
                <div v-show="false" class="UploadStatus flex-align-between" @click="
                    options == null || options == true
                      ? goWord(itemSon.lessonId,itemSon.wordName)
                      : goWordPersonal(itemSon.workName)
                  ">
                  <img :src="
                      itemSon.uploadCount == 0
                        ? UploadStatusImg1
                        : UploadStatusImg2
                    " />
                  <span>{{
                    itemSon.uploadCount == 0 ? "未上传" : "已上传"
                  }}</span>
                </div>
                <div class="worksNum flex-align-between" @click="
                    options == null || options == true
                      ? goWord(itemSon.lessonId,itemSon.wordName)
                      : goWordPersonal(itemSon.workName)
                  ">
                  {{ itemSon.uploadCount || itemSon.count }}份作品
                </div>
                <div class="upload flex-align-between" v-if="false" :class="`${index + 'uploadTip' + indexSon}`" @click="
                    qrcodeUpload(
                      `${index + 'uploadTip' + indexSon}`,
                      itemSon.uploadCount,
                      itemSon.lessonId,
                      itemSon.mpCodePath
                    )
                  ">
                  <img src="@/assets/showreel/uploading.png" />
                  <span class="uploadText">上传</span>
                  <div class="uploadTipQr flex-column-center" v-if="
                      qrstatus &&
                      identification == `${index + 'uploadTip' + indexSon}`
                    ">
                    <div class="container flex-align-center">
                      <img :src="itemSon.mpCodePath" />
                    </div>
                    <span>微信扫码</span>
                    <span>上传本课作品</span>
                  </div>
                </div>
              </div>
              <div class="delSet" @click="
                  checkedBox(options ? itemSon.lessonId : itemSon.workName)
                " v-if="!DleBtn">
                <img :src="[
                    arr.includes(itemSon.lessonId) ||
                    arr.includes(itemSon.workName)
                      ? require('@/assets/showreel/delB.png')
                      : require('@/assets/showreel/delA.png'),
                  ]" />
              </div>
            </el-timeline-item>
          </el-timeline>
        </li>
      </ul>
      <div class="placeholder flex-column-center" v-if="Object.keys(worksList).sort().reverse().length == 0">
        <div></div>
        <span>暂无内容</span>
      </div>
      <!-- <img :src="../../assets/assistant/qrcode.jpg"> -->
      <!-- v-if="worksList[item].length == 0" -->
    </div>

    <div class="projection" v-else>
      <div class="mianbox flex-align-between" v-if="resource">
        <!-- v-else -->
        <div class="mianImg">
          <Viewers ref="Viewers" :imgArr="imgArr" @lantern="lantern" :options="options" v-if="imgArr.length != 0"
            @fatherMethod="report" @getChildInfo="getChildInfo">
          </Viewers>
          <div class="ReportPopup">
            <el-dialog title="" :show-close="false" :visible.sync="ReportPopup" :close-on-click-modal="false" center>
              <div class="mianshowreel" v-if="reportType">
                <div class="Evaluationsubject" v-if="reportData.title != null">
                  【{{ reportData.title }}】作品测评报告
                </div>
                <div class="handleClose" @click="handleClose">
                  <img src="@/assets/showreel/Close.png" />
                </div>
                <div class="mianshowreelSon">
                  <div v-if="testStatus == 1" class="appraisalMianBox">
                    <div class="evaluate mianSon">
                      <div class="titlebox flex-align">
                        <img src="../../assets/assistant/pingjia.png" />
                        <span class="titleText">综合评价</span>
                      </div>
                      <div class="container flex-align-center">
                        <div class="containerT">
                          <div class="figurebox flex-align-between">
                            <div class="figure" v-for="(item, index) in 3" :key="index">
                              <img v-if="item == figureLevel[reportData.level]"
                                src="../../assets/assistant/figure.png" />
                            </div>
                          </div>
                          <div class="progressBar"></div>
                          <div class="progressBarText flex-align-between">
                            <span style="color: #ffdb9c">待提升</span>
                            <span style="color: #f5aa49">良好</span>
                            <span style="color: #ed7e04">优秀</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="appraisal mianSon">
                      <div class="titlebox flex-align">
                        <img src="../../assets/assistant/ceping.png" />
                        <span class="titleText">整体测评</span>
                        <span class="subheading">（点击单字即可查看单字点评）</span>
                      </div>
                      <div class="container flex-align-center">
                        <div class="containerC flex-align-between">
                          <div class="prev carousel" :class="{ prevAff: prevType }" @click="slidePre"></div>
                          <div class="carouselMian">
                            <slider ref="slider" :options="sliderinit" @slide="slide">
                              <slideritem v-for="(item, index) in reportData.testList" :key="index">
                                <div class="textbox flex-column-center" :class="{
                                    textSonAff: appraisalIindex == index,
                                  }" @click="switchover(index)">
                                  <div class="">
                                    <div class="textSonT">
                                      <img src="../../assets/assistant/tian.png" />
                                      <img :src="item.imagePath" />
                                    </div>
                                    <div class="textSonImg flex-align-between">
                                      <div class="imgbox" v-for="(
                                          itemGrandson, indexGrandson
                                        ) in starList[
                                          reportData.testList[index].level
                                        ]" :key="indexGrandson">
                                        <img :src="itemGrandson" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </slideritem>
                            </slider>
                          </div>
                          <div class="next carousel" :class="{
                              nextAff:
                                nextType && this.reportData.testList.length > 5,
                            }" @click="slideNext"></div>
                        </div>
                      </div>
                    </div>
                    <div class="remarkOn mianSon">
                      <div class="titlebox flex-align">
                        <img src="../../assets/assistant/remarkOn.png" />
                        <span class="titleText">单字点评</span>
                      </div>
                      <div class="container flex-align-center">
                        <div class="containerB flex-column-center">
                          <div class="comparison flex-align-between" v-if="
                              reportData != '' &&
                              reportData.testList.length != 0
                            ">
                            <div class="wordBox flex-column-center">
                              <span>原字</span>
                              <div class="wordImg" @click="amplification(0)">
                                <img :src="
                                    options == false
                                      ? reportData.testList[appraisalIindex]
                                          .wordCover
                                      : reportData.wordCover
                                  " />
                              </div>
                            </div>
                            <div class="wordBox flex-column-center">
                              <span>待测字</span>
                              <div class="wordImg wordImgs" @click="amplification(1)">
                                <img src="../../assets/assistant/tian.png" />
                                <img :src="
                                    reportData.testList[appraisalIindex]
                                      .imagePath
                                  " />
                              </div>
                            </div>
                            <div class="wordBox flex-column-center" @click="amplification(2)">
                              <span>AI智能比对</span>
                              <div class="wordImg wordImgs">
                                <img src="../../assets/assistant/tian.png" />
                                <img :src="
                                    options == false
                                      ? reportData.testList[appraisalIindex]
                                          .wordShadowCover
                                      : reportData.wordShadowCover
                                  " />
                                <img :src="
                                    reportData.testList[appraisalIindex]
                                      .imagePath
                                  " />
                                <img :src="
                                    options == false
                                      ? reportData.testList[appraisalIindex]
                                          .wordSkillCover
                                      : reportData.wordSkillCover
                                  " />
                              </div>
                            </div>
                          </div>
                          <div class="remark" v-if="
                              reportData != '' &&
                              reportData.testList.length != 0
                            ">
                            <!-- ---分数：{{reportData.testList[appraisalIindex].score}} -->
                            <span>评语</span>
                            <div class="remarkBox" v-if="reportData != ''">
                              <p v-html="
                                  reportData.testList[appraisalIindex]
                                    .evaluateContent
                                "></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="generated" v-else v-loading="loading" element-loading-text="测评生成中"
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)">
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
        <div class="workR" :class="[{ workRAff: broadsideType }, { workRW: delStatus }]">
          <div class="packUp flex-align-center" @click="packUp">
            <img :src="broadsideType ? img2 : img1" />
          </div>
          <div class="workImgList">
            <div class="workImgbox flex-align-center" :class="{ workImgAff: viewerVal == index }"
              v-for="(item, index) in imgArr" :key="index">
              <img class="label" :src="labels[item.level]" v-if="item.score > 0" />
              <div class="flex-column-center">
                <div class="Img" @click="pitchOn(index, item.id, item.status)">
                  <img :src="item.img2" />
                </div>
                <!-- <span style="color: #999999">{{ item.name }}</span> -->
              </div>
              <div class="delwords flex-column-center" v-if="delStatus">
                <img src="@/assets/assistant/del.png" @click="
                    options
                      ? deleteTestList(item.id, index)
                      : deleteQQTestList(item.id, index)
                  " />
                <span>删除</span>
              </div>
            </div>
          </div>
          <div class="workBtn flex-align-center">
            <!-- @click="report" -->
            <div class="flex-align-center" :class="{ workBtntype: workBtntype }" @click="Administration">
              {{ workBtntype ? "取消" : "管理" }}
            </div>
          </div>

          <!-- <div class="workBtn flex-align-center" v-show="false">
            <div class="flex-align-center" @click="report">AI测评</div>
          </div> -->
        </div>
        <!-- !reportType -->
        <div class="qrcodeTip" v-if="options">
          <div class="qrcodeDom flex-align">
            <div class="qrcodeImg flex-align-center">
              <img src="@/assets/assistant/qrcode.jpg" />
            </div>
            <div class="qrcodetext flex-column">
              <span>微信扫描二维码，</span>
              <span>打开小程序</span>
            </div>
          </div>
          <div class="text flex-align-center">
            <div class="textGuidance flex-column-between">
              <!-- <div class="textGuidance flex-align"> -->
              <!-- 教师登录“首师优字教师端小程序”后，点击<img src="../../assets/assistant/camera.png" />同步投影使用。同步投影 使用 -->
              <div>教师登录“首师优字教师端小程序”后，</div>
              <div>
                点击
                <img src="../../assets/assistant/camera.png" />同步投影使用。同步投影 使用
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="placeholder flex-column-center" v-else-if="resource == false && options == false">
        <div></div>
        <span>暂无内容</span>
      </div>
      <div class="projections flex-align-center" v-else>
        <div class="projectionText flex-column">
          <div class="hint">提示：</div>
          <div class="guidance flex-align-center">
            <span>教师登录“首师优字教师端小程序”后，点击</span>
            <img src="../../assets/assistant/camera.png" />
            <span>同步投影 使用</span>
          </div>
          <div class="qrcode flex-align-center">
            <div class="qrcodeDom flex-align">
              <div class="qrcodeImg flex-align-center">
                <img src="@/assets/assistant/qrcode.jpg" />
              </div>
              <div class="qrcodetext flex-column">
                <span>微信扫描二维码，</span>
                <span>打开小程序</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="base"></div>
    <div class="commentImg">
      <!-- width="30%" -->
      <el-dialog :visible.sync="commenVisible" center :show-close="false" :modal-append-to-body="false">
        <div class="commentImgbox flex-align-between">
          <div class="prev carousel" :class="{ prevAff: popupIndex == 0 }" @click="slidePrePop"></div>
          <div class="classpopBox flex-align-center">
            <div class="classpopBoxImg1 classpopBoxImg" v-if="popupIndex == 0">
              <img :src="amplifier[0]" />
            </div>
            <div class="classpopBoxImg2 classpopBoxImg" v-if="popupIndex == 1">
              <img src="../../assets/assistant/tian.png" />
              <img :src="amplifier[1]" />
            </div>
            <div class="classpopBoxImg3 classpopBoxImg" v-if="popupIndex == 2">
              <img :src="amplifier[2][0]" />
              <img :src="amplifier[2][1]" />
              <img :src="amplifier[2][2]" />
              <img src="../../assets/assistant/tian.png" />
            </div>
          </div>
          <div class="next carousel" :class="{ nextAff: popupIndex == 2 }" @click="slideNextPop"></div>
          <div class="popClose" @click="commenVisible = false"></div>
        </div>
      </el-dialog>
    </div>
    <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
  </div>
</template>

<script>
  import entrance from "../../Common_components/head/entrance";
  import {
    slider,
    slideritem
  } from "vue-concise-slider";
  import VueViewer from "vue-viewerjs";
  import Viewers from "../attendclass/ViewerRecord.vue";
  import subscribe from "../../Common_components/Popup/subscribe.vue";
  import {
    mapState,
    mapMutations
  } from "vuex";
  export default {
    data() {
      return {
        worksList: [],
        UploadStatusImg1: require("../../assets/showreel/wait.png"),
        UploadStatusImg2: require("../../assets/showreel/accomplish.png"),
        weeks: [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ],
        changeable: true,
        //判断是否有投影资源
        resource: false,
        loading: false,
        testStatus: null,
        hintText: {
          0: "生成测评中",
          1: "完成",
          2: "请使用本课的写字单进行测评",
        },
        viewerVal: 0,
        nowadayId: "",
        isShow: true,
        imgArr: [],
        imgList: [],
        starList: {
          1: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
          ],
          2: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
          ],
          3: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
          ],
          4: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
          ],
          5: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
          ],
        },
        //label
        labels: {
          1: require("../../assets/assistant/Toimprove.png"),
          2: require("../../assets/assistant/Toimprove.png"),
          3: require("../../assets/assistant/well.png"),
          4: require("../../assets/assistant/well.png"),
          5: require("../../assets/assistant/excellent.png"),
        },
        broadsideType: false,
        img1: require("../../assets/assistant/packUp1.png"),
        img2: require("../../assets/assistant/packUp2.png"),
        //文字轮播
        sliderinit: {
          currentPage: 0,
          tracking: false,
          thresholdDistance: 100,
          thresholdTime: 300,
          infinite: 6,
          slidesToScroll: 6,
          freeze: true, //禁止拖动
        },
        //测试索引
        appraisalIindex: 0,
        prevType: false,
        nextType: false,
        // workImgType: 0,
        //切换到测评
        reportType: false,
        figureLevel: {
          1: 1,
          2: 1,
          3: 2,
          4: 2,
          5: 3,
        },
        //WebSocket
        websock: null, //建立的连接
        lockReconnect: false, //是否真正建立连接
        timeout: 5 * 1000, //20秒一次心跳
        timeoutObj: null, //心跳心跳倒计时
        serverTimeoutObj: null, //心跳倒计时
        timeoutnum: null, //断开 重连倒计时
        date: "",
        closeJunction: '{"msg":"心跳操作","code":209}',
        reportData: "",
        throttle: 0,
        lessonId: null,
        websockType: null,
        wordData: "",
        amplifier: [
          [1],
          [1],
          [1, 2, 3]
        ],
        popupIndex: 0,
        commenVisible: false,
        qrcodeIndex: null,
        qrcodeIndexSon: null,
        identification: null,
        addHover: false,
        qrstatus: false,
        centerDialogVisible2: false,
        ReportPopup: false,
        options: true,
        // options: false,
        Selectedstate: false,
        lessonName: null,
        EvaluationText: "",
        class1: "学生作品集",
        class2: "",
        workBtntype: false,
        delStatus: false,
        arr: [],
        DleBtn: true,
      };
    },
    // props: ['wordData'],
    components: {
      entrance,
      slider,
      slideritem,
      VueViewer,
      Viewers,
      subscribe,
    },
    //方法
    methods: {
      ...mapMutations(["hint_data"]),
      changTip(value) {
        if (value) {
          this.hint_data("暂无活动");
          this.centerDialogVisible2 = true;
        }
      },
      clear(bool) {
        this.centerDialogVisible2 = bool;
      },
      renderTime(date) {
        if (this.options == null || this.options == true) {
          var dateee = new Date(date).toJSON();
          let data = new Date(+new Date(dateee) + 8 * 3600 * 1000)
            .toISOString()
            .replace(/T/g, " ")
            .replace(/\.[\d]{3}Z/, "");
          data = data.slice(10, 19);
          return data;
        } else {
          let data = date.slice(10, 19);
          return data;
        }
      },
      //获取课时列表数据 --学生作品集合
      async getTeacherWorkHistory() {
        let resData = await this.$Api.Myclass.getTeacherWorkHistory();
        this.worksList = resData.data;
      },
      //我的作品集合
      async getQQTestList() {
        let resData = await this.$Api.Myclass.getQQTestList();
        this.worksList = resData.data;
      },

      //获取课节相关id信息
      // 学生作品集合
      async goWord(lessonId, wordName) {
        this.class2 = wordName;
        let data = {
          lessonId: lessonId,
        };
        let resData = await this.$Api.Myclass.getLessonParams(data);
        let value = JSON.parse(resData.data.params);

        this.changeable = false;
        this.wordData = {
          wordName: wordName,
          taskId: value.taskId,
          unitId: value.unitId,
          wordId: value.wordId,
          classId: value.classId,
          type: value.type,
          gradeId: value.gradeId,
          termId: value.termId,
          whence: lessonId,
          changeable: this.changeable,
          options: this.options,
        };
        sessionStorage.setItem("Flush", JSON.stringify(this.wordData));
        this.lessonId = lessonId;
        this.getWordImg(this.lessonId);
        this.initWebSocket();
      },
      goMyword(lessonName) {
        this.class2 = lessonName;
        this.changeable = false;
        this.wordData = {
          lessonName: lessonName,
          changeable: this.changeable,
          options: false,
        };
        sessionStorage.setItem("Flush", JSON.stringify(this.wordData));
        this.goWordPersonal(lessonName);
        this.initWebSocket();
      },
      //我的作品集合
      async goWordPersonal(lessonName) {
        let data = {
          name: lessonName,
        };
        let resData = await this.$Api.Myclass.getQQTestDetail(data);

        if (resData.data.length == 0) {
          this.imgArr = [];
          this.resource = false;
          return;
        }

        this.resource = true;
        this.imgArr = [];
        for (let i = 0; i < resData.data.length; i++) {
          let angle =
            resData.data[i].params != null ?
            JSON.parse(resData.data[i].params).deg :
            0;
          this.imgArr.push({
            img1: resData.data[i].filePath,
            img2: resData.data[i].thumbFilePath,
            name: resData.data[i].bindUserName,
            id: resData.data[i].id,
            level: resData.data[i].level,
            status: resData.data[i].status,
            deg: angle,
            L: null,
            T: null,
            multiples: 1,
            score: resData.data[i].score,
          });
        }
        this.imgArr = this.imgArr.reverse();
        if (this.resource) this.estimate();
      },

      //WebSocket
      initWebSocket() {
        //建立连接
        let wsuri =
          "yunapi.cnuzi.com/api/learn/socket/projection/" +
          sessionStorage.getItem("teacherId") +
          "/" +
          this.wordData.wordId +
          "/" +
          this.wordData.classId +
          "/" +
          this.lessonId;
        //建立连接
        this.websock = new WebSocket("wss://" + wsuri);
        //连接成功
        this.websock.onopen = this.websocketonopen;
        //连接错误
        this.websock.onerror = this.websocketonerror;
        //接收信息
        this.websock.onmessage = this.websocketonmessage;
        //连接关闭
        // this.websock.onclose = this.websocketclose;
      },
      reconnect() {
        //重新连接
        var that = this;
        if (that.lockReconnect) {
          return;
        }
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        that.timeoutnum && clearTimeout(that.timeoutnum);
        that.timeoutnum = setTimeout(function () {
          //新连接
          that.initWebSocket();
          that.lockReconnect = false;
        }, 5000);
      },
      reset() {
        //重置心跳
        var that = this;
        //清除时间
        clearTimeout(that.timeoutObj);
        clearTimeout(that.serverTimeoutObj);
        //重启心跳
        that.start();
      },
      start() {
        //开启心跳
        var self = this;
        self.timeoutObj && clearTimeout(self.timeoutObj);
        self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
        self.timeoutObj = setTimeout(function () {
          //这里发送一个心跳，后端收到后，返回一个心跳消息
          if (self.websock.readyState == 1) {
            self.websock.send(self.date);
          } else {
            //否则重连
            self.reconnect();
          }
          self.serverTimeoutObj = setTimeout(function () {
            //超时关闭
            self.websock.close();
          }, self.timeout);
        }, self.timeout);
      },
      websocketonopen() {
        //连接成功事件
        this.websocketsend();
        //提示成功
        //开启心跳
        this.start();
      },
      websocketonerror(e) {
        //连接失败事件
        //错误
        //重连
        this.reconnect();
      },
      websocketclose(e) {
        //连接关闭事件
        //提示关闭
        //重连
        // this.reconnect();
      },
      websocketonmessage(event) {
        //接收服务器推送的信息
        console.log("111111111111111111111111", event.data);
        let data = JSON.parse(event.data);
        if (this.throttle == 0 && data.data.code == 1002) {
          console.log("接收服务器推送的信息----1002");
          this.options ?
            this.getWordImg(this.lessonId) :
            this.goWordPersonal(this.wordData.lessonName);
          this.throttle = 1;
        }
        if (data.data.code == 1001) {
          // this.getWordImg(this.lessonId);
          console.log("接收服务器推送的信息----1001");
          this.options ?
            this.getWordImg(this.lessonId) :
            this.goWordPersonal(this.wordData.lessonName);
        }
        data.code = 200;
        this.date = JSON.stringify(data);
        //收到服务器信息，心跳重置
        this.reset();
      },
      websocketsend() {
        let msg = {
          code: 2001,
          data: JSON.stringify(this.$route.query),
        };
        //向服务器发送信息

        this.websock.send(JSON.stringify(msg));
      },
      //右侧作业列表收起切换
      packUp() {
        this.broadsideType = !this.broadsideType;
      },
      //测评
      slide(data) {
        if (data.currentPage == 0) {
          this.nextType = true;
          this.prevType = false;
        } else if (data.currentPage > this.reportData.testList.length - 6) {
          this.nextType = false;
          this.prevType = true;
        } else {
          this.nextType = true;
          this.prevType = true;
        }
      },

      //测试上下一页
      slideNext() {
        this.$refs.slider.$emit("slideNext");
      },
      //测试上一页
      slidePre() {
        this.$refs.slider.$emit("slidePre");
      },
      //整体测评点击切换
      switchover(index) {
        this.appraisalIindex = index;
        this.spreadPdf(index);
      },
      //点击有车作业列表切换图片 -- 测试内容
      pitchOn(index, id, status) {
        this.appraisalIindex = 0;
        this.reportType = false;
        this.viewerVal = index;

        this.nowadayId = id;
        this.testStatus = status;
        this.testStatus = status;
        let that = this;
        this.$nextTick(() => {
          that.$refs.Viewers.Index = that.viewerVal;
        });
      },
      //测评切换
      report() {
        if (this.options == null || this.options == true) {
          this.getreport();
        } else {
          this.getAssessReport();
        }

        if (this.testStatus == 0) {
          this.reportType = true;
          this.ReportPopup = true;
          this.loading = true;
        } else if (this.testStatus == 1) {
          this.reportType = true;
          this.ReportPopup = true;
          this.loading = false;
        } else if (this.testStatus == 2) {
          this.$confirm("报告生成失败,请联系管理员", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {})
            .catch(() => {});
        } else if (this.testStatus == 3) {
          this.$confirm("该内容非本课作品，请检查后重新提交", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {})
            .catch(() => {});
        } else if (this.testStatus == 4) {
          this.$confirm("未识别到要测评的生字，请重新拍摄", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {})
            .catch(() => {});
        } else if (this.testStatus == 5) {
          this.$confirm("请将写字单拍摄完整（包含外框）后，再进行测评", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {})
            .catch(() => {});
        } else if (this.testStatus == 6) {
          this.$confirm("该字暂不支持测评", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {})
            .catch(() => {});
        }
      },
      //学生测评报告
      async getreport() {
        let data = {
          uploadId: this.nowadayId,
        };
        let resData = await this.$Api.Myclass.getTeachAssessReport(data);
        this.reportData = resData.data;
        if (this.reportData.testList.length != 0) {
          this.spreadPdf(0);
        }
      },
      //个人测评报告
      async getAssessReport() {
        let data = {
          uploadId: this.nowadayId,
          type: 3,
        };
        let resData = await this.$Api.Myclass.getAssessReport(data);
        this.reportData = resData.data;
        if (this.reportData.testList.length != 0) {
          this.spreadPdf(0);
        }
      },
      getChildInfo(index, id, status) {
        // this.pitchOn(index, id, status)
        // this.nowadayId = id;
      },
      //向父组件图片放大弹窗传递数据
      spreadPdf(value) {
        let spread;
        if (this.options == false) {
          spread = [
            [this.reportData.testList[value].wordCover],
            [this.reportData.testList[value].imagePath],
            [
              this.reportData.testList[value].wordShadowCover,
              this.reportData.testList[value].imagePath,
              this.reportData.testList[value].wordSkillCover,
            ],
          ];
        } else {
          spread = [
            [this.reportData.wordCover],
            [this.reportData.testList[value].imagePath],
            [
              this.reportData.wordShadowCover,
              this.reportData.testList[value].imagePath,
              this.reportData.wordSkillCover,
            ],
          ];
        }

        this.amplifier = spread;
      },
      //图片放大
      amplification(index) {
        this.commenVisible = true;
        this.popupIndex = index;
      },
      lantern(data) {
        data ? this.prev() : this.next();
      },
      prev() {
        if (this.viewerVal > 0) {
          this.viewerVal--;
          this.pitchOn(
            this.viewerVal,
            this.imgArr[this.viewerVal].id,
            this.imgArr[this.viewerVal].status
          );
        }
      },
      next() {
        if (this.viewerVal < this.imgArr.length - 1) {
          this.viewerVal++;
          this.pitchOn(
            this.viewerVal,
            this.imgArr[this.viewerVal].id,
            this.imgArr[this.viewerVal].status
          );
        }
      },
      //学生作品列表
      async getWordImg(lessonId) {
        let data = {
          wordId: this.wordData.wordId,
          lessonId: lessonId,
        };
        let resData = await this.$Api.Myclass.queryTeaUploadWorksList(data);

        if (resData.data.length == 0) {
          this.imgArr = [];
          this.resource = false;
          return;
        }

        this.resource = true;
        this.imgArr = [];
        for (let i = 0; i < resData.data.length; i++) {
          let angle =
            resData.data[i].params != null ?
            JSON.parse(resData.data[i].params).deg :
            0;
          this.imgArr.push({
            img1: resData.data[i].filePath,
            img2: resData.data[i].thumbFilePath,
            name: resData.data[i].bindUserName,
            id: resData.data[i].id,
            level: resData.data[i].level,
            status: resData.data[i].status,
            deg: angle,
            L: null,
            T: null,
            multiples: 1,
            score: resData.data[i].score,
          });
        }
        this.imgArr = this.imgArr.reverse();
        if (this.resource) this.estimate();
      },
      //判断当前索引
      estimate() {
        let that = this;
        let result = this.imgArr.findIndex((item) => {
          return item.id == that.nowadayId;
        });
        if (result != -1) {
          this.viewerVal = result;
        } else if (this.viewerVal > this.imgArr.length - 1) {
          this.viewerVal = this.imgArr.length - 1;
        }
        this.nowadayId = this.imgArr[this.viewerVal].id;
        this.testStatus = this.imgArr[this.viewerVal].status;
        if (!this.reportType) {
          that.$nextTick(() => {
            console.log(" this.resource", this.resource);
            this.$refs.Viewers.Index = this.viewerVal;
          });
        }
      },
      slidePrePop() {
        if (this.popupIndex > 0) {
          this.popupIndex--;
        }
      },
      slideNextPop() {
        if (this.popupIndex < 2) {
          this.popupIndex++;
        }
      },
      renewal(value) {
        //页面销毁时关闭长连接
        this.changeable = value;
        this.imgArr = [];
        this.throttle = 0;
        this.viewerVal = 0;
        this.resource = false;
        sessionStorage.removeItem("Flush");
        if (value && this.websock) {
          this.websocketclose();
          this.websock.send(this.closeJunction);
        }
      },

      enter(indexSon, index) {
        this.qrcodeIndexSon = indexSon;
        this.qrcodeIndex = index;
        this.addHover = true;
      },
      leave(indexSon, index, lessonId) {
        this.qrcodeIndexSon = indexSon;
        this.qrcodeIndex = index;
        this.addHover = false;
      },
      qrcodeUpload(value, num, lessonId, mpCodePath) {
        let that = this;
        if (num == 0 && mpCodePath == null) {
          this.goWord(lessonId);
        } else if (mpCodePath != null && num == 0) {
          let img = new Image();
          img.src = mpCodePath;
          img.onload = function () {
            that.identification = value;
            that.qrstatus = true;
          };
          img.onerror = function () {
            that.goWord(lessonId);
          };
        } else if (mpCodePath == null && num != 0) {
          this.goWord(lessonId);
        } else if (mpCodePath != null && num != 0) {
          let img = new Image();
          img.src = mpCodePath;
          img.onload = function () {
            that.identification = value;
            that.qrstatus = true;
          };
          img.onerror = function () {
            that.goWord(lessonId);
          };
        }
      },
      handleALL(event) {
        let one = document.getElementsByClassName(
          `${this.qrcodeIndex + "uploadTip" + this.qrcodeIndexSon}`
        )[0];

        if (one) {
          if (!one.contains(event.target)) {
            this.qrstatus = false;
          }
        }
      },
      stuWorks() {
        this.options = true;
        this.class1 = "学生作品集";
        this.DleBtn = true;
        this.arr = [];
        this.getTeacherWorkHistory();
      },
      personalWorks() {
        this.options = false;
        this.class1 = "我的作品集";
        this.DleBtn = true;
        this.arr = [];
        this.getQQTestList();
      },
      handleClose() {
        this.ReportPopup = false;
      },
      direction() {
        this.changeable = true;
      },

      //作品集管理

      Administration() {
        this.workBtntype = !this.workBtntype;
        this.delStatus = !this.delStatus;
      },
      // 选中框
      checkedBox(value) {
        if (this.arr.includes(value)) {
          this.arr = this.arr.filter(function (ele) {
            return ele != value;
          });
        } else {
          this.arr.push(value);
        }
      },
      //删除选项
      showDleBtn() {
        this.DleBtn = false;
      },
      //取消按钮
      cancel() {
        this.DleBtn = true;
        this.arr = [];
      },
      //删除按钮
      del() {
        let _this = this;
        if (this.arr.length == 0) return;
        this.$confirm(
            "删除所选课程记录，同时所包含作品也将被清除，确定要删除吗？",
            "确认删除", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              customClass: "MessClass",
              confirmButtonClass: "warningClass",
              center: true,
            }
          )
          .then(() => {
            _this.options ?
              _this.deleteTeacherWork(_this.arr) :
              _this.deleteQQWork(_this.arr);
          })
          .catch(() => {
            _this.arr = [];
          });
      },

      //删除时间轴学生作品列表
      async deleteTeacherWork(arr) {
        let data = {
          ids: arr.toString(),
        };
        let resData = await this.$Api.Myclass.deleteTeacherWork(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.arr = [];
          this.getTeacherWorkHistory();
        }
      },
      //删除时间轴我的作品列表
      async deleteQQWork(arr) {
        let data = {
          ids: arr.toString(),
        };
        let resData = await this.$Api.Myclass.deleteQQWork(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.arr = [];
          this.getQQTestList();
        }
      },
      IndexChange(index) {
        if (index == this.imgArr.length - 1 && index == this.viewerVal) {
          this.imgArr.splice(index, 1);
          this.viewerVal = index - 1;
          let that = this;
          this.$nextTick(() => {
            that.$refs.Viewers.Index = that.viewerVal;
          });
        }
      },
      async deleteTestList(id, index) {
        let data = {
          ids: id,
        };

        let resData = await this.$Api.Myclass.deleteTestList(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.IndexChange(index);
          this.imgArr.splice(index, 1);
          this.resource = this.imgArr.length == 0 ? false : true;
        }
      },
      async deleteQQTestList(id, index) {
        let data = {
          ids: id,
        };
        let resData = await this.$Api.Myclass.deleteQQTestList(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.IndexChange(index);
          this.imgArr.splice(index, 1);
          this.resource = this.imgArr.length == 0 ? false : true;
        }
      },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {},
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
      let that = this;
      if (sessionStorage.getItem("Flush")) {
        this.wordData = JSON.parse(sessionStorage.getItem("Flush"));
        if (this.wordData.lessonName) {
          this.changeable = this.wordData.changeable;
          this.goWordPersonal(this.wordData.lessonName);
          this.options = this.wordData.options;
        } else {
          this.changeable = this.wordData.changeable;

          this.lessonId = this.wordData.whence;
          this.goWord(this.lessonId, this.wordData.wordName);
          this.options = this.wordData.options;
        }
      }
      if (this.options == true) {
        this.getTeacherWorkHistory();
      } else {
        this.getQQTestList();
      }
      //监测游览器返回清空状态
      window.addEventListener(
        "popstate",
        function (e) {
          that.renewal();
        },
        false
      );
    },
  };
</script>
<style lang='less' scoped>
  @import "./less/showreel";
</style>